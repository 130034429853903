<template>
  <ion-badge class="to-top clickable-item-hov" @click="jumpToTop">
    <span class="to-top-inner"
      ><i color="white" class="ti-angle-up to-top-icon" /><span class="label">Back to top</span></span
    >
  </ion-badge>
</template>
<script lang="ts" setup>
const jumpToTop = () => {
  const document = useDocument();
  document.value?.querySelector('.layout-scroll-area')?.scrollTo({ top: 0, behavior: 'smooth' });
};
</script>
<style lang="sass" scoped>

.to-top
  width: 160px
  height: 34px
  background-color: #431C89 !important
  font-weight: normal
  user-select: none !important
  text-transform: none !important
  border-bottom-left-radius: 10px !important
  border-bottom-right-radius: 10px !important
  border-top-right-radius: 10px !important
  border-top-left-radius: 10px !important
  background: #671CA4
  font-size: 16px
  line-height: 28px
  @media(max-width: 420px)
    width: 34px
.to-top-inner
  margin: 0 auto
  user-select: none !important
.to-top-icon
  margin-right: 16px
  position: relative
  top: 2px
  @media(max-width: 420px)
    margin: 0
.label
  display: none
  @media(min-width: 420px)
    display: inline-block
</style>
